import { gql } from "urql";

import { ShippingAddressConfig } from "./queries/GetAllOrders";

export const GetAnonymousCart = gql`
  query getAnonymousCart($cartId: String!) {
    anonymousCart(cartId: $cartId) {
      customerId
      id
      customer {
        email
      }
      lineItems {
        id
        discounts {
          totalDiscount {
            currencyCode
            centAmount
          }
          includedDiscounts {
            name
            description
            amount {
              centAmount
              currencyCode
            }
          }
        }
        name {
          en
        }
        variant {
          name
          images {
            url
          }
        }
        quantity
        price {
          value {
            centAmount
            currencyCode
          }
          discounted {
            value {
              centAmount
              currencyCode
            }
            discount {
              name
            }
          }
        }
        quantity
      }
      totalPrice {
        centAmount
        currencyCode
      }
      shippingInfo {
        price {
          centAmount
          currencyCode
        }
        shippingMethodName
      }
      shippingAddress {
        email
        mobile
        apartment
        state
        streetName
        streetNumber
        postalCode
        region
        firstName
        lastName
        city
        additionalAddressInfo
      }
    }
  }
`;
export const AddContactsSubscriptionMutation = gql`
  mutation addContactsSubscription($input: addBundleToCartDraft!) {
    addBundleToCart(addBundleToCartRequest: $input) {
      id
      lineItems {
        id
        quantity
        variant {
          sku
        }
        custom {
          customFieldsRaw {
            name
            value
          }
        }
      }
    }
  }
`;

export const AddCognitoTokenToCartMutation = gql`
  mutation addCognitoTokenToCart(
    $cognitoToken: String!
    $store: String!
    $marketingEmailsOptin: Boolean
    $anonymousCartId: String
    $firstName: String
    $lastName: String
  ) {
    websiteAuthenticate(
      cognitoToken: $cognitoToken
      store: $store
      anonymousCartId: $anonymousCartId
      marketingEmailsOptin: $marketingEmailsOptin
      firstName: $firstName
      lastName: $lastName
    ) {
      access_token
      refresh_token
      expiresAt
    }
  }
`;

export const UpdateShippingMethodMutation = gql`
  mutation setShippingMethod($shippingMethodKey: String!) {
    setShippingMethod(shippingMethodKey: $shippingMethodKey) {
      shippingInfo {
        shippingMethodName
      }
    }
  }
`;

export const UpdateShippingAddressMutation = gql`
  mutation updateShippingAddress($input: addressDraft!) {
    setShippingAddress(addressRequest: $input) {
      id
      version
      lineItems {
        id
        quantity
        variant {
          sku
        }
      }
      shippingAddress {
        id
        key
        title
        firstName
        lastName
        apartment
        streetName
        streetNumber
        postalCode
        city
        state
      }
    }
  }
`;
export const GetAvailableShippingMethodQuery = gql`
  query {
    shippingMethodsForCart {
      shippingMethods {
        name
        description
        key
        predicate
        zoneRates {
          shippingRates {
            price {
              centAmount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const GetShippingMethodQuery = gql`
  query {
    shippingMethodsForCart {
      shippingMethods {
        name
        key
        description
        zoneRates {
          shippingRates {
            price {
              centAmount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const GetOriginKeyAndPaymentMethodsQuery = gql`
  query ($hostUrl: String!) {
    paymentOriginKey(hostUrl: $hostUrl) {
      originKeys
    }
    paymentMethods {
      groups
      paymentMethods
      storedPaymentMethods
    }
  }
`;

export const MakePaymentMutation = gql`
  mutation makePayment($request: cardPaymentInput!) {
    makePayment(paymentRequest: $request, storePaymentMethod: true) {
      customerId
      orderNumber
    }
  }
`;

export const GetCartQuery = gql`
  query {
    cart {
      customerId
      id
      customer {
        email
      }
      lineItems {
        id
        discounts {
          totalDiscount {
            currencyCode
            centAmount
          }
          includedDiscounts {
            name
            description
            amount {
              centAmount
              currencyCode
            }
          }
        }
        name {
          en
        }
        variant {
          name
          images {
            url
          }
        }
        quantity
        price {
          value {
            centAmount
            currencyCode
          }
          discounted {
            value {
              centAmount
              currencyCode
            }
            discount {
              name
            }
          }
        }
        quantity
      }
      totalPrice {
        centAmount
        currencyCode
      }
      shippingInfo {
        price {
          centAmount
          currencyCode
        }
        shippingMethodName
      }
      shippingAddress {
        email
        mobile
        apartment
        state
        streetName
        streetNumber
        postalCode
        region
        firstName
        lastName
        city
        additionalAddressInfo
        company
      }
    }
  }
`;

export const GetAnonymousTokenMutation = gql`
  mutation ($storeName: String!) {
    anonymousToken(store: $storeName) {
      access_token
      refresh_token
      expiresAt
    }
  }
`;

export const RefreshTokenQuery = gql`
  mutation ($storeName: String!, $refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken, store: $storeName) {
      access_token
      refresh_token
      expiresAt
    }
  }
`;

export const RemoveLineItemMutation = gql`
  mutation ($itemParam: removeItemDraft!) {
    removeItem(removeItemRequest: $itemParam) {
      customerId
      id
      customer {
        email
      }
      lineItems {
        id
        name {
          en
        }
        variant {
          name
          images {
            url
          }
        }
        quantity
        price {
          value {
            centAmount
            currencyCode
          }
          discounted {
            value {
              centAmount
              currencyCode
            }
            discount {
              name
            }
          }
        }
        quantity
      }
      totalPrice {
        centAmount
        currencyCode
      }
      shippingInfo {
        price {
          centAmount
          currencyCode
        }
        shippingMethodName
      }
      shippingAddress {
        email
        mobile
        apartment
        state
        streetName
        streetNumber
        postalCode
        region
        firstName
        lastName
        city
      }
    }
  }
`;

export const UpdateSubscription = gql`
  mutation updateSubscription($input: updateSubscriptionDraft!) {
    updateSubscription(updateSubscriptionRequest: $input) {
      nextShipmentDate
      isSubscription
      status
      frequency
      creditCardNumber
    }
  }
`;

export const ReOrderMutation = gql`
  mutation reOrder($orderNumber: String!) {
    reOrder(orderNumber: $orderNumber) {
      orderNumber
    }
  }
`;

export const InitiateReOrderMutation = gql`
  mutation initiateReOrder($orderNumber: String!) {
    initiateReOrder(orderNumber: $orderNumber) {
      id
      lineItems {
        id
        quantity
        name {
          en
        }
        custom {
          customFieldsRaw {
            name
            value
          }
        }
        discounts {
          totalDiscount {
            currencyCode
            centAmount
          }
          includedDiscounts {
            name
            description
            amount {
              centAmount
              currencyCode
            }
          }
        }
        price {
          value {
            currencyCode
            centAmount
          }
        }
        variant {
          name
        }
      }
      totalPrice {
        centAmount
      }
    }
  }
`;

export const CompleteReOrderMutation = gql`
  mutation completeReOrder($cartId: String!) {
    completeReOrder(cartId: $cartId) {
      orderNumber
    }
  }
`;

export const GetOrder = gql`
  query order($orderNumber: String!) {
    order(orderNumber: $orderNumber) {
      orderNumber
      createdAt
      taxedPrice {
        totalGross {
          centAmount
          currencyCode
        }
      }
      shippingInfo {
        shippingMethodName
      }
      totalPrice {
        centAmount
        currencyCode
      }
      shippingAddress {
        firstName
        lastName
        streetName
        streetNumber
        city
        postalCode
        region
        phone
        company
        country {
          code
        }
        phone
      }
      subscriptionDetails {
        nextShipmentDate
        status
        isSubscription
        creditCardNumber
        frequency
      }
      lineItems {
        name {
          en
        }
        variant {
          name
          images {
            url
            label
          }
        }
        custom {
          customFieldsRaw {
            name
            value
          }
        }
        quantity
        discounts {
          includedDiscounts {
            name
            amount {
              centAmount
              currencyCode
            }
          }
        }
        price {
          value {
            currencyCode
            centAmount
          }
        }
      }
    }
  }
`;

export interface GetCustomerResponse {
  customer: {
    email: string;
    savedCreditCard: string;
    addresses: ShippingAddressConfig[];
  };
}

export const GetCustomer = gql`
  query customer {
    customer {
      email
      savedCreditCard
      addresses {
        apartment
        city
        email
        firstName
        lastName
        mobile
        postalCode
        region
        state
        streetName
        streetNumber
        country {
          code
        }
      }
    }
  }
`;
