import { MIDDLEWARE_ACCESS_TOKEN_KEY, MIDDLEWARE_EXPIRY_TIME, MIDDLEWARE_REFRESH_TOKEN_KEY } from "../constants/token";

export const saveToken = (accessToken: string, refreshToken: string, expiryTime: string): void => {
  localStorage.setItem(MIDDLEWARE_ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(MIDDLEWARE_REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(MIDDLEWARE_EXPIRY_TIME, expiryTime);
};

export const clearToken = (): void => {
  localStorage.removeItem(MIDDLEWARE_ACCESS_TOKEN_KEY);
  localStorage.removeItem(MIDDLEWARE_REFRESH_TOKEN_KEY);
  localStorage.removeItem(MIDDLEWARE_EXPIRY_TIME);
};
