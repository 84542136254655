import { differenceInSeconds } from "date-fns";
import { RouteUpdateArgs, WrapPageElementBrowserArgs } from "gatsby";
import React, { FC } from "react";

import AppWrapper from "./src/components/AppWrapper/AppWrapper";
import { getEnvironment } from "./src/config/environment";

const { navigate } = require("gatsby");

const config = getEnvironment();

const GATSBY_ADYEN_JS_SOURCE = config.GATSBY_ADYEN_ENVIROMENT;
const GATSBY_ADYEN_JS_INTEGRITY_KEY = config.GATSBY_ADYEN_JS_INTEGRITY_KEY;

export const onRouteUpdate = ({ location }: RouteUpdateArgs) => {
  if (location.hash) {
    const id = location.hash.replace(/^#/, "");
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
  }
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    // Send GA tag
    const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    // @ts-ignore
    if (typeof ga === `function`) {
      // @ts-ignore
      window.ga(`set`, `page`, pagePath);
      // @ts-ignore
      window.ga(`send`, `pageview`);
    }

    // Send GTM tag
    const data = window[`dataLayer`];
    if (data) {
      //data can be null/undefined sometimes
      data.push({ event: "gatsby-route-change" });
    }
  }, 50);
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  // if the new route is part of the list above, scroll to top (0, 0)
  const currentPosition = getSavedScrollPosition(location);
  window.scrollTo({ top: 0, behavior: "smooth" });
  return false;
};

export const onInitialClientRender = () => {
  const script = document.createElement("script");
  script.src = "https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js";
  document.head.appendChild(script);
  const plattarScript = document.createElement("script");
  plattarScript.src = "https://cdn.jsdelivr.net/npm/@plattar/plattar-ar-adapter/build/es2019/plattar-ar-adapter.min.js";
  document.head.appendChild(plattarScript);

  if (config.GATSBY_MIDDLEWARE_STORE_NAME && config.GATSBY_MIDDLEWARE_ENABLED) {
    // Adyen JS script
    const aydenScript = document.createElement("script");
    aydenScript.src = GATSBY_ADYEN_JS_SOURCE ?? "";
    aydenScript.integrity = GATSBY_ADYEN_JS_INTEGRITY_KEY ?? "";
    aydenScript.crossOrigin = "anonymous";
    document.head.appendChild(aydenScript);
  }

  window.addEventListener("popstate", (event) => {
    console.debug("popstate", event.state, window.history.state, window.location.href);
  });

  // @ts-ignore
  window.___navigateTimeStamp = null;
  // @ts-ignore
  window.___navigateCurrentPath = null;
  // @ts-ignore
  const ___oldNavigate = window.___navigate;
  // @ts-ignore
  window.___navigate = (to, options) => {
    const currentPath = `${location.pathname}${location.search}${location.hash}`;
    if (decodeURI(currentPath) === decodeURI(to)) {
      return;
    }
    // @ts-ignore
    const now = new Date();
    // @ts-ignore
    if (window.___navigateTimeStamp) {
      // @ts-ignore
      const diffInSecs = differenceInSeconds(now, window.___navigateTimeStamp);
      // @ts-ignore
      if (diffInSecs < 1.2 && decodeURI(to) === decodeURI(window.___navigateCurrentPath)) {
        return;
      }
    }
    console.debug("go to", to);
    // @ts-ignore
    window.___navigateTimeStamp = now;
    // @ts-ignore
    window.___navigateCurrentPath = decodeURI(to);
    ___oldNavigate(to, options);
  };
};

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => <AppWrapper>{element}</AppWrapper>;
