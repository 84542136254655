import { Amplify, Auth } from "aws-amplify";
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";

import { CognitoUser, WebsiteAuthenticateResponse } from "../components/Iris/api/interface";
import { AddCognitoTokenToCartMutation } from "../components/Iris/api/irisQuery";
import { urqlClient } from "../components/Iris/api/UrqlClient";
import { getEnvironment } from "../config/environment";
import { MIDDLEWARE_ACCESS_TOKEN_KEY, MIDDLEWARE_EXPIRY_TIME, MIDDLEWARE_LOGGING_IN_FLAG, MIDDLEWARE_REFRESH_TOKEN_KEY } from "../constants/token";
import { clearToken, saveToken } from "../utils/TokenHelper";

interface ContextType {
  user: CognitoUser | null | undefined;
  login: (_usernameOrEmail: string, _password: string) => Promise<CognitoUser | null>;
  logout: (_redirectPath?: string) => Promise<void>;
}

const config = getEnvironment();
const MIDDLEWARE_STORE_NAME = config.GATSBY_MIDDLEWARE_STORE_NAME;

export const UserContext = createContext<ContextType>({ user: null, login: () => Promise.resolve(null), logout: () => Promise.resolve() });

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<CognitoUser | null>(null);

  const webAuthenticate = useCallback((cognitoUser: CognitoUser): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      localStorage.setItem(MIDDLEWARE_LOGGING_IN_FLAG, "true");
      urqlClient
        .mutation<WebsiteAuthenticateResponse>(AddCognitoTokenToCartMutation, {
          cognitoToken: cognitoUser.signInUserSession.idToken.jwtToken ?? "",
          store: MIDDLEWARE_STORE_NAME,
        })
        .toPromise()
        .then((result) => {
          if (result.data?.websiteAuthenticate) {
            saveToken(
              result?.data?.websiteAuthenticate?.access_token,
              result?.data?.websiteAuthenticate?.refresh_token,
              result?.data?.websiteAuthenticate?.expiresAt,
            );
            localStorage.removeItem(MIDDLEWARE_LOGGING_IN_FLAG);
            // window.location.reload();
            resolve(result);
          } else {
            // eslint-disable-next-line no-console
            console.error("error on clientWithoutHeader");
            reject("error on clientWithoutHeader");
          }
        });
    });
  }, []);

  const login = useCallback(
    (usernameOrEmail: string, password: string): Promise<CognitoUser> =>
      new Promise<CognitoUser>((resolve, reject) => {
        Auth.signIn(usernameOrEmail, password)
          .then(async (cognitoUser) => {
            const data = await Auth.currentAuthenticatedUser();
            await webAuthenticate(data);
            setUser(cognitoUser);
            resolve(cognitoUser);
          })
          .catch((err) => {
            if (err.code === "UserNotFoundException") {
              err.message = "Invalid username or password";
            }
            reject(err);
          });
      }),
    [webAuthenticate],
  );

  const logout = useCallback(
    (redirectPath?: string): Promise<void> =>
      Auth.signOut().then((data) => {
        clearToken();
        if (redirectPath) {
          window.location.href = redirectPath; //can't use navigate, need to trigger refresh page
        }
        setUser(null);
        return data;
      }),
    [],
  );

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: config.GATSBY_COGNITO_REGION,
        userPoolId: config.GATSBY_COGNITO_USER_POOL_ID,
        userPoolWebClientId: config.GATSBY_COGNITO_USER_POOL_CLIENT_ID,
      },
    });
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (localStorage.getItem(MIDDLEWARE_ACCESS_TOKEN_KEY) && localStorage.getItem(MIDDLEWARE_REFRESH_TOKEN_KEY)) {
          setUser(user);
        } else {
          logout();
        }
      })
      .catch(() => setUser(null));
  }, [logout]);

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): ContextType => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("`useUser` hook must be used within a `UserProvider` component");
  }
  return context;
};
