import React, { ReactElement, useEffect, useState } from "react";

const ClientOnly = ({ children }: { children: React.ReactElement }): ReactElement | null => {
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return children;
};

export default ClientOnly;
