export enum CountryTypes {
  CA = "CA",
  AU = "AU",
  GB = "GB",
  NZ = "NZ",
}

export interface IEnvironment {
  GATSBY_ADYEN_JS_SOURCE: string;
  GATSBY_ADYEN_JS_INTEGRITY_KEY: string;
  GATSBY_ADYEN_ENVIROMENT: string;
  GATSBY_ADYEN_ORIGIN_KEY_DOMAIN: string;

  GATSBY_MIDDLEWARE_STORE_NAME: string;
  GATSBY_MIDDLEWARE_END_POINT: string;
  GATSBY_MIDDLEWARE_ENABLED: boolean;

  GATSBY_ALGOLIA_INDEX: string;
  GATSBY_ALGOLIA_APP_ID: string;
  GATSBY_ALGOLIA_SEARCH_KEY: string;

  GATSBY_CDN_HOST: string;

  GATSBY_PRISMIC_REPO_NAME: string;

  GATSBY_MYSHOPIFY_URL: string;
  GATSBY_SHOPIFY_SHOP_NAME: string;
  GATSBY_SHOPIFY_ACCESS_TOKEN: string;

  GATSBY_STORE_COUNTRY: CountryTypes;
  GATSBY_SITE_URL: string;
  GATSBY_STORE_LOCALE: string;

  GATSBY_ENABLE_FRENCH: boolean;
  GATSBY_FACEBOOK_PIXEL_ID: string;

  GATSBY_GOOGLE_ANALYTICS_TRACKING_ID: string;
  GATSBY_GOOGLE_ANALYTICS_COOKIE_DOMAIN: string;
  GATSBY_GOOGLE_TAG_MANAGER: string;

  GATSBY_SENTRY_DSN: string;
  GATSBY_APPOINTMENT_AVAILABILITY: number;

  GATSBY_LOCATIONS_LINK: string;
  GATSBY_UPDATED_CONTACTS_BUILDER: boolean;
  GATSBY_CURRENCY_CODE: string;

  GATSBY_APPOINTMENT_BOOKING_NAME: string;
  GATSBY_ONLINE_BOOKING: boolean;

  GATSBY_COGNITO_REGION: string;
  GATSBY_COGNITO_USER_POOL_ID: string;
  GATSBY_COGNITO_USER_POOL_CLIENT_ID: string;

  GATSBY_DESKTOP_VTO: boolean;
  GATSBY_ENABLE_VTO: boolean;

  GATSBY_GOOGLE_MAPS_API: string;

  GATSBY_FLAG_WIDE_CAROUSEL: boolean;

  GATSBY_ENABLE_EMAIL_PRESCRIPTION: boolean;
  GATSBY_REMOVE_RX_VALIDATION: boolean;
  GATSBY_EXISTING_CUSTOMER_PROMPTS_PRESCRIPTION: boolean;
  GATSBY_PRESCRIPTION_UPLOAD_URL: string;

  GATSBY_ALLOW_SUBSCRIPTION: boolean;
  GATSBY_GA_TRACKING_ID: string;

  GATSBY_DISABLE_COMPARE_AT: boolean;

  GATSBY_TIKTOK_PIXEL_ID: string;
}

const allowedCountries = Object.values(CountryTypes);
const allowedLocales = ["en-AU", "en-CA", "en-GB", "en-NZ"];
const allowedCurrencyCodes = ["CAD", "AUD", "GBP", "NZD"];

const stringToBoolean = (text: string | undefined): boolean => text === "true";

export const getEnvironment = (): IEnvironment => {
  const env: IEnvironment = {
    GATSBY_ADYEN_JS_SOURCE: process.env.GATSBY_ADYEN_JS_SOURCE || "",
    GATSBY_ADYEN_JS_INTEGRITY_KEY: process.env.GATSBY_ADYEN_JS_INTEGRITY_KEY || "",
    GATSBY_MIDDLEWARE_STORE_NAME: process.env.GATSBY_MIDDLEWARE_STORE_NAME || "",
    GATSBY_MIDDLEWARE_END_POINT: process.env.GATSBY_MIDDLEWARE_END_POINT || "no GATSBY_MIDDLEWARE_END_POINT",
    GATSBY_MIDDLEWARE_ENABLED: stringToBoolean(process.env.GATSBY_MIDDLEWARE_ENABLED),
    GATSBY_ALGOLIA_INDEX: process.env.GATSBY_ALGOLIA_INDEX || "",
    GATSBY_ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID || "",
    GATSBY_CDN_HOST: process.env.GATSBY_CDN_HOST || "",
    GATSBY_PRISMIC_REPO_NAME: process.env.GATSBY_PRISMIC_REPO_NAME || "",
    GATSBY_MYSHOPIFY_URL: process.env.GATSBY_MYSHOPIFY_URL || "",
    GATSBY_STORE_COUNTRY: (process.env.GATSBY_STORE_COUNTRY || "AU") as CountryTypes,
    GATSBY_ENABLE_FRENCH: stringToBoolean(process.env.GATSBY_ENABLE_FRENCH),
    GATSBY_SITE_URL: process.env.GATSBY_SITE_URL || "localhost",
    GATSBY_STORE_LOCALE: process.env.GATSBY_STORE_LOCALE || "en-AU",
    GATSBY_FACEBOOK_PIXEL_ID: process.env.GATSBY_FACEBOOK_PIXEL_ID || "disabled",
    GATSBY_GOOGLE_ANALYTICS_TRACKING_ID: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID || "disabled",
    GATSBY_GOOGLE_ANALYTICS_COOKIE_DOMAIN: process.env.GATSBY_GOOGLE_ANALYTICS_COOKIE_DOMAIN || "",
    GATSBY_GOOGLE_TAG_MANAGER: process.env.GATSBY_GOOGLE_TAG_MANAGER || "disabled",
    GATSBY_SENTRY_DSN: process.env.GATSBY_SENTRY_DSN || "",
    GATSBY_APPOINTMENT_AVAILABILITY: isNaN(Number(process.env.GATSBY_APPOINTMENT_AVAILABILITY))
      ? 8
      : Number(process.env.GATSBY_APPOINTMENT_AVAILABILITY),
    GATSBY_LOCATIONS_LINK: process.env.GATSBY_LOCATIONS_LINK || "",
    GATSBY_UPDATED_CONTACTS_BUILDER: stringToBoolean(process.env.GATSBY_UPDATED_CONTACTS_BUILDER),
    GATSBY_CURRENCY_CODE: process.env.GATSBY_CURRENCY_CODE || "",
    GATSBY_ADYEN_ENVIROMENT: process.env.GATSBY_ADYEN_ENVIROMENT || "",
    GATSBY_ADYEN_ORIGIN_KEY_DOMAIN: process.env.GATSBY_ADYEN_ORIGIN_KEY_DOMAIN || "",
    GATSBY_ALGOLIA_SEARCH_KEY: process.env.GATSBY_ALGOLIA_SEARCH_KEY || "",
    GATSBY_SHOPIFY_SHOP_NAME: process.env.GATSBY_SHOPIFY_SHOP_NAME || "",
    GATSBY_SHOPIFY_ACCESS_TOKEN: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN || "",
    GATSBY_APPOINTMENT_BOOKING_NAME: process.env.GATSBY_APPOINTMENT_BOOKING_NAME || "",
    GATSBY_ONLINE_BOOKING: stringToBoolean(process.env.GATSBY_ONLINE_BOOKING),
    GATSBY_COGNITO_REGION: process.env.GATSBY_COGNITO_REGION || "",
    GATSBY_COGNITO_USER_POOL_ID: process.env.GATSBY_COGNITO_USER_POOL_ID || "",
    GATSBY_COGNITO_USER_POOL_CLIENT_ID: process.env.GATSBY_COGNITO_USER_POOL_CLIENT_ID || "",
    GATSBY_DESKTOP_VTO: stringToBoolean(process.env.GATSBY_DESKTOP_VTO),
    GATSBY_ENABLE_VTO: stringToBoolean(process.env.GATSBY_ENABLE_VTO),
    GATSBY_GOOGLE_MAPS_API: process.env.GATSBY_GOOGLE_MAPS_API || "",
    GATSBY_FLAG_WIDE_CAROUSEL: stringToBoolean(process.env.GATSBY_FLAG_WIDE_CAROUSEL),
    GATSBY_ENABLE_EMAIL_PRESCRIPTION: stringToBoolean(process.env.GATSBY_ENABLE_EMAIL_PRESCRIPTION),
    GATSBY_REMOVE_RX_VALIDATION: stringToBoolean(process.env.GATSBY_REMOVE_RX_VALIDATION),
    GATSBY_EXISTING_CUSTOMER_PROMPTS_PRESCRIPTION: stringToBoolean(process.env.GATSBY_EXISTING_CUSTOMER_PROMPTS_PRESCRIPTION),
    GATSBY_PRESCRIPTION_UPLOAD_URL: process.env.GATSBY_PRESCRIPTION_UPLOAD_URL || "",
    GATSBY_ALLOW_SUBSCRIPTION: stringToBoolean(process.env.GATSBY_ALLOW_SUBSCRIPTION),
    GATSBY_GA_TRACKING_ID: process.env.GATSBY_GA_TRACKING_ID || "disabled",
    GATSBY_DISABLE_COMPARE_AT: stringToBoolean(process.env.GATSBY_DISABLE_COMPARE_AT),
    GATSBY_TIKTOK_PIXEL_ID: process.env.GATSBY_TIKTOK_PIXEL_ID || "disabled"
  };

  Object.keys(env).forEach((key) => {
    const convertedKey = key as keyof IEnvironment;
    if (env[convertedKey] === "" && typeof env[convertedKey] !== "boolean" && typeof env[convertedKey] !== "number") {
      throw `missing: ${key}`;
    }
  });

  if (!allowedCountries.includes(env.GATSBY_STORE_COUNTRY)) {
    throw `Country must be in: ${allowedCountries.join(" ")}`;
  }

  if (!allowedLocales.includes(env.GATSBY_STORE_LOCALE)) {
    throw `Locale must be in: ${allowedLocales.join(" ")}`;
  }

  if (!allowedCurrencyCodes.includes(env.GATSBY_CURRENCY_CODE)) {
    throw `Locale must be in: ${allowedCurrencyCodes.join(" ")}`;
  }

  return env;
};
