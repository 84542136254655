import React from "react";
// eslint-disable-next-line import/no-unresolved
import { Toaster } from "react-hot-toast";

import { UserProvider } from "../../hooks/useCognito";
import ClientOnly from "../ClientOnly";
import SentryTracking from "../SentryTracking";

const AppWrapper = ({ children }: { children: React.ReactElement }): JSX.Element => {
  return (
    <SentryTracking>
      <ClientOnly>
        <Toaster position="top-right" reverseOrder={false} />
      </ClientOnly>
      <UserProvider>{children}</UserProvider>
    </SentryTracking>
  );
};

export default AppWrapper;
