import { OperationContext } from "@urql/core";
import { authExchange } from "@urql/exchange-auth";
import { DocumentNode } from "graphql";
// eslint-disable-next-line import/no-unresolved
import { toast } from "react-hot-toast";
import {
  cacheExchange,
  CombinedError,
  createClient,
  dedupExchange,
  fetchExchange,
  makeOperation,
  Operation,
  OperationResult,
  TypedDocumentNode,
} from "urql";

import { getEnvironment } from "../../../config/environment";
import { MESSAGES } from "../../../constants/messages";
import {
  MIDDLEWARE_ACCESS_TOKEN_KEY,
  MIDDLEWARE_EXPIRY_TIME,
  MIDDLEWARE_LOGGING_IN_FLAG,
  MIDDLEWARE_REFRESH_TOKEN_KEY,
} from "../../../constants/token";
import { clearToken, saveToken } from "../../../utils/TokenHelper";
import { DidAuthErrorProps } from "./interface";
import { GetAnonymousTokenMutation, RefreshTokenQuery } from "./irisQuery";

export interface AuthResponse {
  token: string;
  refreshToken: string;
}

export interface ParamsWillAuthError {
  authState: AuthResponse;
}

export interface ParamsAddAuthToOperation {
  authState: AuthResponse;
  operation: Operation;
}

export interface ParamsGetAuth {
  authState: AuthResponse;
  mutate<Data = unknown, Variables extends object = Record<string, never>>(
    query: DocumentNode | TypedDocumentNode<Data, Variables> | string,
    variables?: Variables,
    context?: Partial<OperationContext>,
  ): Promise<OperationResult<Data>>;
}

const config = getEnvironment();

const MIDDLEWARE_STORE_NAME = config.GATSBY_MIDDLEWARE_STORE_NAME ?? "";

export const getErrorString = (error: CombinedError | undefined): string => {
  if (error && error.graphQLErrors?.length > 0) {
    return error.graphQLErrors[0].message;
  }
  return error?.message ?? "Error occur, please contact us for support";
};

const addAuthToOperation = ({ authState, operation }: ParamsAddAuthToOperation): Operation => {
  if (!authState || !authState?.token) {
    return operation;
  }
  const fetchOptions = typeof operation.context.fetchOptions === "function" ? operation.context.fetchOptions() : operation.context.fetchOptions || {};

  if (authState?.token === null) {
    return makeOperation(operation.kind, operation, {
      ...operation.context,
      fetchOptions: {
        ...fetchOptions,
      },
    });
  }
  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        Authorization: authState.token,
      },
    },
  });
};

const getAuth = async ({ authState, mutate }: ParamsGetAuth): Promise<AuthResponse | null> => {
  const isClient = typeof window !== "undefined";
  const isAddCognitoTokenToCart = isClient ? localStorage.getItem(MIDDLEWARE_LOGGING_IN_FLAG) : false;
  if (isAddCognitoTokenToCart) {
    return null;
  }
  if (!authState && typeof window !== "undefined") {
    const token = localStorage.getItem(MIDDLEWARE_ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(MIDDLEWARE_REFRESH_TOKEN_KEY);
    const expiryTime = localStorage.getItem(MIDDLEWARE_EXPIRY_TIME);

    if (token && refreshToken && expiryTime) {
      const targetTime = new Date(expiryTime).valueOf();
      const currentTime = new Date().valueOf(); //10 mins before expiry

      if (currentTime >= targetTime) {
        try {
          const response = await mutate(RefreshTokenQuery, {
            storeName: MIDDLEWARE_STORE_NAME,
            refreshToken: refreshToken,
          });
          const result = response?.data?.refreshToken;
          if (result) {
            // save the new tokens in storage for next restart
            saveToken(result.access_token, result.refresh_token, result.expiresAt);
            // return the new tokens
            return {
              token: result.access_token,
              refreshToken: result.refresh_token,
            };
          } else {
            clearToken();
            // eslint-disable-next-line no-console
            console.error("refresh token failed");
            toast.error(MESSAGES.SESSION_EXPIRED);

            return null;
          }
        } catch {
          toast.error(MESSAGES.SESSION_EXPIRED);
          return null;
        }
      } else {
        // not expired
        return { token, refreshToken };
      }
    } //if there is no enought middleware data in localstorage, get a new anon token, refresh token and expiry time
    else {
      try {
        const response = await mutate(GetAnonymousTokenMutation, { storeName: MIDDLEWARE_STORE_NAME });
        if (response?.data?.anonymousToken) {
          const result = response?.data?.anonymousToken;
          saveToken(result.access_token, result.refresh_token, result.expiresAt);

          return {
            token: result.access_token,
            refreshToken: result.refresh_token,
          };
        }
      } catch {
        // eslint-disable-next-line no-console
        console.error("getting anon token failed");
        //if get anon token fail...then nothing would work....
        return null;
      }
    }
  }

  return { token: authState?.token, refreshToken: authState?.refreshToken };
};

export const AuthErrorCodes = ["E001", "E002", "E003", "E004", "E048"];
const didAuthError = ({ error }: DidAuthErrorProps): boolean => {
  const errorCode = error.graphQLErrors?.[0]?.extensions?.code;
  return AuthErrorCodes.includes(errorCode); //only retry if it's authorization related error
};

const willAuthError = ({ authState }: ParamsWillAuthError): boolean => {
  const isClient = typeof window !== "undefined";
  const isAddCognitoTokenToCart = isClient ? localStorage.getItem(MIDDLEWARE_LOGGING_IN_FLAG) : false;
  if (isAddCognitoTokenToCart) {
    clearToken();
    return true;
  }
  if (!authState) return true;
  return false;
};

export const urqlClient = createClient({
  url: config.GATSBY_MIDDLEWARE_END_POINT,
  exchanges: [dedupExchange, cacheExchange, authExchange({ addAuthToOperation, getAuth, didAuthError, willAuthError }), fetchExchange],
  requestPolicy: "network-only",
});
