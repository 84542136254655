// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Sentry from "@sentry/react";
import { navigate } from "gatsby";
import React, { PropsWithChildren, useEffect } from "react";

import { getEnvironment } from "../config/environment";

const config = getEnvironment();

const ENVIRONMENT = process.env.NODE_ENV;

const SentryTracking: React.FC<PropsWithChildren> = ({ children }) => {
  const locale = config.GATSBY_STORE_LOCALE;
  const country = config.GATSBY_STORE_COUNTRY;

  useEffect(() => {
    if (ENVIRONMENT === "production") {
      Sentry.init({
        dsn: config.GATSBY_SENTRY_DSN,
        environment: ENVIRONMENT,
        denyUrls: [/https?:\/\/static\.klaviyo\.com/i, /https?:\/\/maps\.googleapis\.com/i, /https?:\/\/app\.plattar\.com/i],
      });
      Sentry.configureScope((scope) => {
        if (locale) {
          scope.setTag("page.locale", locale);
        }
        scope.setExtra("Shop country", country);
      });
    }
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={({ error }): void => navigate("/internal-error/", { state: { error } })} showDialog={false}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryTracking;
